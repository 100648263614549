import {useEffect, useState} from "react";
import {getSunTypeGradient, SolarGradients} from "../domain/sun-type";
import {SunTypes} from "../components/sun_path";
import {sleep} from "../utils/async";


/* Animation of Gradients */

function interpolateColor(color1, color2, ratio) {
    const r = Math.round(color1.r + (color2.r - color1.r) * ratio);
    const g = Math.round(color1.g + (color2.g - color1.g) * ratio);
    const b = Math.round(color1.b + (color2.b - color1.b) * ratio);
    return { r, g, b };
}

function createColorAnimation(gradient1, gradient2, duration) {
    const colors1 = SolarGradients[gradient1];
    const colors2 = SolarGradients[gradient2];
    const numFrames = duration * 60; // 60 frames per second

    return new Promise((resolve, reject) => {
        let currentFrame = 0;
        const interval = setInterval(() => {
            if (currentFrame >= numFrames) {
                clearInterval(interval);
                resolve(); // Resolve the promise when animation completes
                return;
            }

            const ratio = currentFrame / numFrames;
            const intermediateColors = colors1.map((color1, index) => {
                const color2 = colors2[index] || colors2[colors2.length - 1]; // If gradient2 has fewer colors, use the last color
                return interpolateColor(color1.color, color2.color, ratio);
            });

            const gradientString = intermediateColors.map(color => `rgb(${color.r}, ${color.g}, ${color.b})`).join(',');
            document.body.style.background = `linear-gradient(to top, ${gradientString})`;
            currentFrame++;
        }, 1000 / 60); // 60 frames per second
    });
}

function createPercentageAnimation(gradient1, gradient2, duration) {
    const colors1 = SolarGradients[gradient1];
    const colors2 = SolarGradients[gradient2];
    const numFrames = duration * 60; // 60 frames per second

    return new Promise((resolve, reject) => {
        let currentFrame = 0;
        const interval = setInterval(() => {
            if (currentFrame >= numFrames) {
                clearInterval(interval);
                resolve(); // Resolve the promise when animation completes
                return;
            }

            const ratio = currentFrame / numFrames;
            const intermediateColors = colors1.map((color1, index) => {
                const color2 = colors2[index] || colors2[colors2.length - 1]; // If gradient2 has fewer colors, use the last color
                const pct = color1.pct + (color2.pct - color1.pct) * ratio; // Interpolate percentage
                return { ...color1, pct };
            });

            const gradientString = intermediateColors.map(color => `rgb(${color.color.r}, ${color.color.g}, ${color.color.b}) ${color.pct}%`).join(', ');
            document.body.style.background = `linear-gradient(to top, ${gradientString})`;
            currentFrame++;
        }, 1000 / 60); // 60 frames per second
    });
}

function createCombinedAnimation(gradient1, gradient2, duration) {
    const colors1 = SolarGradients[gradient1];
    const colors2 = SolarGradients[gradient2];
    const numFrames = duration * 60; // 60 frames per second

    return new Promise((resolve, reject) => {
        let currentFrame = 0;
        const interval = setInterval(() => {
            if (currentFrame >= numFrames) {
                clearInterval(interval);
                resolve(); // Resolve the promise when animation completes
                return;
            }

            const ratio = currentFrame / numFrames;
            const intermediateColors = colors1.map((color1, index) => {
                const color2 = colors2[index] || colors2[colors2.length - 1]; // If gradient2 has fewer colors, use the last color
                const intermediateColor = interpolateColor(color1.color, color2.color, ratio);
                const pct = color1.pct + (color2.pct - color1.pct) * ratio; // Interpolate percentage
                return { color: intermediateColor, pct };
            });

            const gradientString = intermediateColors.map(color => `rgb(${color.color.r}, ${color.color.g}, ${color.color.b}) ${color.pct}%`).join(', ');
            document.body.style.background = `linear-gradient(to top, ${gradientString})`;
            currentFrame++;
        }, 1000 / 60); // 60 frames per second
    });
}

export async function runBackgroundAnimation() {
    await sleep(1250)
    await createCombinedAnimation(SunTypes.Night, SunTypes.BlueHour, 0.5)
    await createCombinedAnimation(SunTypes.BlueHour, SunTypes.Twilight, 0.5);
    await createCombinedAnimation(SunTypes.Twilight, SunTypes.Red, 0.5);
    await createCombinedAnimation(SunTypes.Red, SunTypes.UVA, 0.5);
    await createCombinedAnimation(SunTypes.UVA, SunTypes.UVB, 0.5);
    await createCombinedAnimation(SunTypes.UVB, SunTypes.SolarNoon, 0.5);
}

export const useBackgroundAnimation = (sunType, onAnimationEnd) => {
    const [isAnimationRunning, setAnimationRunning] = useState(false);

    useEffect(() => {
        if (isAnimationRunning) {
            return;
        }

        // Apply the background style to the body element
        document.body.style.background = getSunTypeGradient(sunType);
        document.body.style.height = '100vh'; // Ensure the whole viewport is covered

        // Cleanup function to reset the body styles when the component is unmounted
        return () => {
            document.body.style.background = ''; // Reset background to default
        };
    }, [sunType, isAnimationRunning]);



    const startAnimation = async () => {
        if (isAnimationRunning) {
            return;
        }

        setAnimationRunning(true);
        await runBackgroundAnimation();
        setAnimationRunning(false);
        if (onAnimationEnd) {
            onAnimationEnd()
        }
    };

    return {startBackgroundAnimation: startAnimation, isBackgroundAnimationRunning: isAnimationRunning};
};

