import {Duration} from "luxon";

export const calculateCoordinates = (radius, degrees) => {
    // Convert degrees to radians
    const radians = degrees * (Math.PI / 180);

    // Calculate x and y coordinates
    const x = radius * Math.sin(radians).toFixed(2); // Adjusted for x-coordinate
    const y = -radius * Math.cos(radians).toFixed(2); // Adjusted for y-coordinate

    return { x, y };
};

// This converse the elapsed minutes of the day to degrees in a circle
// with noon at the top, midnight at the bottom, AM to the left and
// PM to the right
export function timeToDegrees(luxonTime) {
    // Validate the input is a Luxon DateTime object
    if (luxonTime.invalid) {
        throw new Error("Invalid DateTime object", luxonTime);
    }

    const midnight = luxonTime.startOf("day");

    // Time difference in milliseconds
    const elapsedMinutes = Math.round(luxonTime.diff(midnight).toMillis() / 1000 / 60);

//    console.log('elapsedMinutes', elapsedMinutes)
    // Total milliseconds in a day
    const minutesInDay = Math.round(Duration.fromObject({ hours: 24 }).as("milliseconds") / 1000 / 60);

    // Fraction of the day
    const fractionOfDay = elapsedMinutes / minutesInDay;

    // Convert to degrees (360 degrees for a full day)
    const degrees = fractionOfDay * 360;

    return (Math.round(degrees) + 180) % 360;
}

// Since noon on the clock is not the same as solar noon, this method
// will adjust the degrees to be centered in relation to solar noon
// (or any given offset) instead
export function calculateSunpathDegrees(sunriseTime, offsetDegrees) {
    const adjustedDegrees = timeToDegrees(sunriseTime) - offsetDegrees;
    return adjustedDegrees > 0 ? adjustedDegrees : (360 + adjustedDegrees) % 360;
}


