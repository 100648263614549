import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles for the date picker
import '../css/App.css';
import {formatDayAndTime, getDateTime} from "../utils/time";

function formatDate(date) {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

function DateSelector({ onChange, onClose, displayDate, timezone }) {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false); // State to manage visibility of date picker
    const datePickerRef = useRef(null);
    const [currentDate, setCurrentDate] = useState(displayDate);

    const decreaseDate = () => {
        const yesterday = currentDate.minus({ days: 1 }); // Use Luxon to subtract days
        setCurrentDate(yesterday); // Update state with the new Luxon DateTime
    };

    const increaseDate = () => {
        const tomorrow = currentDate.plus({ days: 1 }); // Use Luxon to add days
        setCurrentDate(tomorrow); // Update state with the new Luxon DateTime
    };

    const handleDateClick = () => {
        setIsDatePickerOpen(!isDatePickerOpen); // Toggle the date picker visibility
    };

    const handleDateChange = (date) => {
        setCurrentDate(date);
        setIsDatePickerOpen(false); // Close the date picker
    };

    useEffect(() => {
        console.log('Changed current date to', formatDayAndTime(currentDate))
        onChange(currentDate);
    }, [currentDate]);

    return (
        <div className="date-nav">
            <button onClick={decreaseDate}>{'<'}</button>
            {/* Clicking on the span toggles the date picker */}
            <span onClick={handleDateClick} style={{ cursor: 'pointer' }}>
                {formatDate(currentDate)}
            </span>
            <button onClick={increaseDate}>{'>'}</button>
            <span onClick={onClose}>x</span>

            {/* The date picker is conditionally rendered based on `isDatePickerOpen` */}
            {isDatePickerOpen && (
                <div ref={datePickerRef}
                className="date-picker-popup">
                <DatePicker
                    selected={currentDate.toJSDate()}
                    onChange={(jsDate) => handleDateChange(getDateTime(jsDate, timezone))}
                    inline // Display inline within the component
                />
                </div>
            )}
        </div>
    );
}

export default DateSelector;
