import React, {useState} from "react";
import {useSolarClock} from "../providers/solar-clock-provider";
import {formatDay, formatDayAndTime} from "../utils/time";
import DateSelector from "./date_selector";

function AppHeader({darkTheme}) {
    const [isControlsVisible, setIsControlsVisible] = useState(false);
    const {displayDate, handleDisplayDateChange, isDateToday} = useSolarClock()

    const headerClass = darkTheme ? "header dark-mode-text-color" : "header";
    const timeClass = darkTheme ? "headerTime dark-mode-text-color" : "headerTime";
    return <div className={headerClass}><h1>Circadian Clock</h1>
        {!isControlsVisible && <span className={timeClass} onClick={() => setIsControlsVisible(true)}>{isDateToday() ? formatDayAndTime(displayDate) : formatDay(displayDate)}</span>}
        {isControlsVisible && <span className={timeClass}><DateSelector onChange={handleDisplayDateChange} displayDate={displayDate} onClose={() => setIsControlsVisible(false)}/></span>}
    </div>;
}

export default AppHeader;
