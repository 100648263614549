import {useEffect, useState} from "react";
import {useInterval} from "./use-interval";
import {DateTime} from "luxon";


const Today = DateTime.local();
const Tomorrow = Today.plus({ days: 1 }); // Add o

export const Days = {
    Today,
    Tomorrow,
};

const SixtySeconds = 60 * 1000;

export const useCurrentTime = (timezone) => {
    // clock ticking function
    const [currentTime, setCurrentTime] = useState(Days.Today);
    const [isPaused, setIsPaused] = useState(false);

    const count = useInterval(SixtySeconds);

    const syncCurrentTime = () => {
        const now = DateTime.now().setZone(timezone);
        setCurrentTime(now);
    }

    const tickClock = () => {
        if (!isPaused) {
            console.log('\n\n-----------TICK--------------', count)
            syncCurrentTime();
        }
    };

    useEffect(() => {
        tickClock();
    }, [count]);

    const startClock = () => {
        if (isPaused) {
            setIsPaused(false);
            syncCurrentTime();
        }
    }

    const stopClock = () => {
        setIsPaused(true);
    };

    return { currentTime, startClock, stopClock, syncCurrentTime};
}

