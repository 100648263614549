import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {SolarClockProvider} from "./providers/solar-clock-provider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SolarClockProvider><App /></SolarClockProvider>
);
