import {DateTime} from "luxon";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {getDefaultCoordinates} from "../utils/geo";
import {useSunTimes} from "../hooks/use-sun-times";
import {getSunType} from "../domain/sun-type";
import {sleep} from "../utils/async";
import {adjustDay, formatDayAndTime} from "../utils/time";
import SunPath from "./sun_path";
import ThreeDotsBounce from "../icons/three-dots-bounce";

const sunAnimationStartDate = DateTime.fromObject({
    year: 2024,
    month: 4,
    day: 12,
    hour: 1,
    minute: 12,
});

const sunAnimationStopDate = DateTime.fromObject({
    year: 2024,
    month: 4,
    day: 12,
    hour: 13,
    minute: 12,
});

function AnimatedSunPath({startDate = sunAnimationStartDate, stopDate = sunAnimationStopDate, duration = 10, onAnimationEnd, onFrame, currentDate}) {
    const [sunElevationTime, setSunElevationTime] = useState(startDate);
    const coords = useMemo(() => getDefaultCoordinates(), []);
    const sunTimes = useSunTimes(coords, sunAnimationStartDate, 'America/Los_Angeles');
    const {sunrise, sunset, nadir, solarNoon} = sunTimes;
    const currentDateRef = useRef();

    useEffect(() => {
        if (currentDate) {
            console.log('Animation - setting Current Date Ref', currentDate, typeof currentDate)
            currentDateRef.current = currentDate;
        }
    }, [currentDate]);

    function waitForRef(ref, interval = 100) {
        return new Promise((resolve, reject) => {
            const checkInterval = setInterval(() => {
                console.log('Animation - Waiting for ref')
                if (ref.current) {
                    clearInterval(checkInterval);
                    resolve(ref.current);
                }
            }, interval);

            // Optional timeout to prevent infinite loop
            const timeout = setTimeout(() => {
                clearInterval(checkInterval);
                reject(new Error('Timeout waiting for ref to be present'));
            }, 5000); // Timeout after 5 seconds
        });
    }

    const sunType = useMemo(() => {
        return getSunType(sunTimes, sunElevationTime);
    },[sunElevationTime]);

    async function startAnimation(startTime, stopTime, duration, delay = 0, hold = 0) {
        if (delay > 0) {
            await sleep(delay);
        }

        const sameDayStopTime = adjustDay(startTime, stopTime);
        const startMillis = startTime.toMillis();
        const stopMillis = sameDayStopTime.toMillis();

        const frameDuration = 1000 / 60; // Duration per frame in milliseconds (assuming 60 fps)
        const totalFrames = duration * 60; // Total number of frames for the duration
        const timeDifference = stopMillis - startMillis; // Difference between start and stop time in milliseconds
        let currentFrame = 0;

        console.log('Animation - Total Frames', totalFrames);
        console.log('Animation - Frame Duration', frameDuration);
        console.log('Animation - Total Duration', frameDuration * totalFrames);

        let started = false;
        // Return a promise that resolves when the interval is cleared
        return new Promise((resolve) => {
            const animationTimer = setInterval(() => {
                if (!started) {
                    console.log('Animation Started at ', formatDayAndTime(DateTime.now()))
                    started = true;
                }
                if (currentFrame < totalFrames) {
                    // Calculate current time, accounting for direction of animation
                    const progress = currentFrame / totalFrames;
                    const currentTime = startMillis + (timeDifference * progress);
                    const currentDateTime = DateTime.fromMillis(currentTime);
                    setSunElevationTime(currentDateTime); // Update sun elevation time for each frame

                    const isDarkTheme = currentTime < sunrise || currentTime > sunset;
                    onFrame(isDarkTheme);
                    currentFrame++;
                } else {
                    clearInterval(animationTimer);
                    console.log('Animation Stopped at ', formatDayAndTime(DateTime.now()))

                    // After the animation is complete, hold the final position for the specified duration
                    if (hold) {
                        setTimeout(() => {
                            resolve(); // Resolve the promise after holding
                        }, hold);
                    } else {
                        resolve(); // Resolve the promise immediately if no hold
                    }
                }
            }, frameDuration);
        });
    }

    const animateSun = async () => {
        const introAnimationTimer = await startAnimation(startDate, stopDate, duration, 1250, 750)
        await waitForRef(currentDateRef);
        const sunSyncAnimationTimer = await startAnimation(stopDate, currentDateRef.current, 1, 0, 0)
        if (onAnimationEnd) {
            onAnimationEnd();
        }

        return { introAnimationTimer, sunSyncAnimationTimer}
    };

    useEffect(() => {
        let introAnimationTimer, sunSyncAnimationTimer;
        animateSun().then(({ timer1, timer2}) => {
            introAnimationTimer = timer1;
            introAnimationTimer = timer2;
        });

        return () => {
            if (introAnimationTimer) clearInterval(introAnimationTimer); // Cleanup timer on unmount
            if (sunSyncAnimationTimer) clearInterval(sunSyncAnimationTimer); // Cleanup timer on unmount
        };
    }, [startDate, stopDate, duration]);

    return <SunPath sunriseTime={sunrise} sunsetTime={sunset}
                    nadir={nadir} solarNoon={solarNoon} sunType={sunType} sunElevationTime={sunElevationTime}>
        <div className="svg-container"><ThreeDotsBounce/></div>
    </SunPath>;
}

export default AnimatedSunPath;
