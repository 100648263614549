import {getDateTime, getFormattedTimeWithZone, isValidDate} from "../utils/time";
import {SunTypes} from "./sun_path";
import React, {useMemo} from "react";

export const CircadianEventType = {
    Daybreak: 'Daybreak',
    Dawn: 'Dawn',
    Sunrise: 'Sunrise',
    UvaRise: 'UVA Rise',
    UvbRise: 'UVB Rise',
    SolarNoon: 'Solar Noon',
    UvbSet: 'UVB Set',
    UvaSet: 'UVA Set',
    Sunset: 'Sunset',
    Dusk: 'Dusk',
    Nightfall: 'Nightfall',
    Nadir: 'Nadir'
};

export function CircadianEvent({ event, sunType, onHover }) {
    const handleMouseEnter = (e) => {
        e.stopPropagation();
        if (onHover) {
            onHover();
        }
    };

    const eventClass = event.active ? "circadian-event circadian-event-highlight" : "circadian-event";
    return (
        <div
            className={eventClass}
            onMouseEnter={handleMouseEnter} // Event listener for mouse enter
        >
            <span className="label">{event.label}:</span>
            <span className="date">{getFormattedTimeWithZone(event.date)}</span>
        </div>
    );
}

export function CircadianEventsList({ sunTimes, timezone,  onHoverEvent}) {
    const events = useMemo(() => {
        // const activeIndex = events.findIndex((event) => event.date >= sunElevationTime);
        // const activeIndex =  index - 1 % events.length
        // events[activeIndex].active = true;
        return generateCircadianEvents(sunTimes, timezone);
    }, [sunTimes, timezone]);

    const handleOnHoverEvent = (eventLabel, eventDate) => {
        onHoverEvent(eventLabel, getDateTime(eventDate, timezone));
    };

    return (
        <div className="circadian-events-list">
            {events.map((event, index) => (
                <CircadianEvent key={index} event={event} onHover={() => handleOnHoverEvent(event.label, event.date)} />
            ))}
        </div>
    );
}

export function generateCircadianEvents(sunTimes) {
    const {sunrise, sunset, dawn, dusk, uvaRise, uvaSet, uvbRise, uvbSet, solarNoon, nightfall, daybreak, nadir} = sunTimes;

    const circadianEvents = [
        {label: CircadianEventType.Daybreak, date: daybreak, sunType: SunTypes.BlueHour, active: false},
        {label: CircadianEventType.Dawn, date: dawn, sunType: SunTypes.Twilight, active: false},
        {label: CircadianEventType.Sunrise, date: sunrise, sunType: SunTypes.Red, active: false},
        {label: CircadianEventType.UvaRise, date: uvaRise, sunType: SunTypes.UVA, active: false},
        {label: CircadianEventType.UvbRise, date: uvbRise, sunType: SunTypes.UVB, active: false},
        {label: CircadianEventType.SolarNoon, date: solarNoon, sunType: SunTypes.SolarNoon, active: false},
        {label: CircadianEventType.UvbSet, date: uvbSet, sunType: SunTypes.UVB, active: false},
        {label: CircadianEventType.UvaSet, date: uvaSet, sunType: SunTypes.UVA, active: false},
        {label: CircadianEventType.Sunset, date: sunset, sunType: SunTypes.Red, active: false},
        {label: CircadianEventType.Dusk, date: dusk, sunType: SunTypes.Twilight, active: false},
        {label: CircadianEventType.Nightfall, date: nightfall, sunType: SunTypes.BlueHour, active: false},
        {label: CircadianEventType.Nadir, date: nadir, sunType: SunTypes.Night, active: false},
    ];
    return circadianEvents.filter(event => isValidDate(event.date));
}
