import React from 'react';
import {SunTypes, MoonTypes} from "../components/sun_path";
import {UVARiseSun, UVBRiseSun, SunriseSun, SolarNoonSun} from "./sun-svg-icon";
import MoonSVG from "./moon-svg";

const SunIcon = ({sunType = SunTypes.Red}) => {
    if (sunType === SunTypes.UVA) {
        return <UVARiseSun/>
    }
    if (sunType === SunTypes.UVB) {
        return <UVBRiseSun />
    }
    if (sunType === SunTypes.Red) {
        return <SunriseSun />
    }
    if (sunType === SunTypes.SolarNoon) {
        return <SolarNoonSun />
    }
    if (sunType === SunTypes.Twilight) {
        return <MoonSVG />
    }
    if (sunType === SunTypes.BlueHour) {
        return <MoonSVG />
    }
    // Default to Night Sun
    return <MoonSVG />
};

export const isSunIcon = (sunType) => {
    return !MoonTypes.includes(sunType)
}

export const isMoonIcon = (sunType) => {
    return MoonTypes.includes(sunType)
}
export default SunIcon;

