import {useMemo} from "react";
import SunCalc from "suncalc";
import {DateTime} from "luxon";

SunCalc.addTime(10, "uvaRise", "uvaSet")
SunCalc.addTime(30, "uvbRise", "uvbSet")
SunCalc.addTime(0, "zeroRise", "zeroSet")
SunCalc.addTime(-1.125, "-oneRise", "-oneSet")

export function useSunTimes(coordinates, displayDate, timezone) {
// Calculate sun times using SunCalc
    const sunTimes = useMemo(() => {
        const {latitude, longitude} = coordinates;
        const {
            sunrise,
            sunset,
            dawn,
            dusk,
            uvaRise,
            uvaSet,
            uvbRise,
            uvbSet,
            solarNoon,
            nauticalDusk: nightfall,
            nauticalDawn: daybreak,
            nadir,
        } = SunCalc.getTimes(displayDate, latitude, longitude);

        const times = {
            sunrise,
            sunset,
            dawn,
            dusk,
            uvaRise,
            uvaSet,
            uvbRise,
            uvbSet,
            solarNoon,
            nightfall,
            daybreak,
            nadir,
        };

        const luxonTimes = {};
        for (const [key, value] of Object.entries(times)) {
            luxonTimes[key] = DateTime.fromJSDate(value, {zone: timezone});
        }
        return luxonTimes;
    }, [coordinates.latitude, coordinates.longitude, displayDate, timezone]);

    return sunTimes;
}

