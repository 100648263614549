import {useEffect, useState} from "react";

function getInitialCount(intervalDuration) {
    const now = new Date();
    const secondsUntilNextMinute = 60 - now.getSeconds();
    return Math.floor(secondsUntilNextMinute / (intervalDuration / 1000));
}

export function useInterval(intervalDuration) {
    const [count, setCount] = useState(getInitialCount(intervalDuration));

    useEffect(() => {
        let timeoutId;
        const updateTime = () => {
            setCount(prevCount => prevCount + 1);
            const currentTime = new Date();
            const secondsUntilNextMinute = 60 - currentTime.getSeconds();
            timeoutId = setTimeout(updateTime, secondsUntilNextMinute * 1000);
        };

        updateTime();

        return () => clearTimeout(timeoutId);
    }, [intervalDuration]);

    return count;
}

