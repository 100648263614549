import React, {useEffect, useMemo, useState} from 'react';
import './css/App.css';
import SunPath, {SunTypes} from "./components/sun_path";
import {useSolarClock} from "./providers/solar-clock-provider";
import {
    formatDayAndTime,
    getDateTime,
} from "./utils/time";
import {getSunType} from "./domain/sun-type";
import {useBackgroundAnimation} from "./hooks/use-background-animation";
import AnimatedSunPath from "./components/animated_sun_path";
import AppHeader from "./components/app_header";
import Navigation from "./components/navigation";
import {CircadianEventsList} from "./components/circadian_event";
import MoonSVG from "./icons/moon-svg";
import SunIcon from "./icons/sun_icon";

function App() {
    const {
        timezone,
        currentTime, isLocationLoading} = useSolarClock();
    const { isDateToday, today, sunTimes, displayDate } = useSolarClock()
    const [sunElevationTime, setSunElevationTime] = useState(getDateTime(today));
    const [isAnimationDarkMode, setIsAnimationDarkMode] = useState(true);
    const [isHovering, setIsHovering] = useState(false);
    const [isSunAnimationRunning, setIsSunAnimationRunning] = useState(false);

    const sunType = useMemo(() => {
        return getSunType(sunTimes, sunElevationTime);
    }, [sunTimes, sunElevationTime]);

    const {startBackgroundAnimation, isBackgroundAnimationRunning} = useBackgroundAnimation(sunType);

    useEffect(() => {
        void startBackgroundAnimation();
        setIsSunAnimationRunning(true);
    }, []);

    function resetSunElevationTime() {
        if (isHovering) {
            return;
        }

        if (!isDateToday()) {
            console.log('SunElevation: Sunrise', formatDayAndTime(sunrise))
            setSunElevationTime(sunrise);
        } else {
            console.log('SunElevation: current time', formatDayAndTime(currentTime))
            setSunElevationTime(currentTime);
        }
    }

    useEffect(() => {
        resetSunElevationTime();
    }, [sunTimes, currentTime, displayDate, isHovering]);

    const {sunrise, sunset, nadir, solarNoon} = sunTimes;

    const isDarkTheme = useMemo(() => {
            if (isSunAnimationRunning) {
                console.log('isAnimationDarkMode', isAnimationDarkMode)
                return isAnimationDarkMode;
            } else {
                return sunElevationTime < sunrise || sunElevationTime > sunset;
            }
    },[sunElevationTime, sunrise, sunset, isSunAnimationRunning, isAnimationDarkMode]);

    const onHoverEvent = (_eventLabel, eventDate) => {
        setSunElevationTime(eventDate);
        setIsHovering(true)
    };

    const onMouseLeave = () => {
        setIsHovering(false);
    };

    const onSunAnimationEnd = () => {
        setIsSunAnimationRunning(false);
    }

    const onSunAnimationFrame = (isDarkMode) => {
        setIsAnimationDarkMode(isDarkMode);
    }

    const isIntroAnimationRunning = isBackgroundAnimationRunning || isSunAnimationRunning;
    const isAppLoaded = !isLocationLoading && !isIntroAnimationRunning;
    return (
        <div classNameName="App">
            <AppHeader darkTheme={isDarkTheme} />
            <Navigation darkTheme={isDarkTheme} isLocationLoading={!isAppLoaded} />
            {isSunAnimationRunning && <AnimatedSunPath duration={3} onAnimationEnd={onSunAnimationEnd} onFrame={onSunAnimationFrame} currentDate={isLocationLoading ? undefined : sunElevationTime}/>}
            {isAppLoaded && <SunPath sunriseTime={sunrise} sunsetTime={sunset}
                           nadir={nadir} solarNoon={solarNoon} sunType={sunType} sunElevationTime={sunElevationTime}
                           onMouseLeave={onMouseLeave}>
                <CircadianEventsList sunTimes={sunTimes} timezone={timezone} onHoverEvent={onHoverEvent} onMouseLeave={onMouseLeave} />
            </SunPath>}
        </div>
    );
}

export default App;
