import React from 'react';

const SunSvgIcon = ({ sunRadius, longSpikeLength, shortSpikeLength, clipHeight, sunColorStart, sunColorEnd, spikeColor }) => {
    const centerX = 100;
    const centerY = 100;

    // Define positions for spikes
    const spikes = [
        { angle: 0, length: longSpikeLength },
        { angle: 22.5, length: shortSpikeLength },
        { angle: 45, length: longSpikeLength },
        { angle: 67.5, length: shortSpikeLength },
        { angle: 90, length: longSpikeLength },
        { angle: 112.5, length: shortSpikeLength },
        { angle: 135, length: longSpikeLength },
        { angle: 157.5, length: shortSpikeLength },
        { angle: 180, length: longSpikeLength },
        { angle: 202.5, length: shortSpikeLength },
        { angle: 225, length: longSpikeLength },
        { angle: 247.5, length: shortSpikeLength },
        { angle: 270, length: longSpikeLength },
        { angle: 292.5, length: shortSpikeLength },
        { angle: 315, length: longSpikeLength },
        { angle: 337.5, length: shortSpikeLength },
    ];

    return (
        <svg width={sunRadius * 2 * 2}
             viewBox={`0 0 200 200`}
             xmlns="http://www.w3.org/2000/svg">
            <defs>
                <radialGradient id="grad1" cx="50%" cy="50%" r="50%" fx="50%" fy="20%">
                    <stop offset="0%" style={{ stopColor: sunColorStart, stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: sunColorEnd, stopOpacity: 1 }} />
                </radialGradient>
                {clipHeight && (
                    <clipPath id="clip">
                        <rect x="0" y="0" width="200" height={clipHeight} />
                    </clipPath>
                )}
            </defs>
            <g clipPath={clipHeight ? "url(#clip)" : undefined}>
                <circle cx={centerX} cy={centerY} r={sunRadius} fill="url(#grad1)" />
                <circle cx={centerX} cy={centerY} r={sunRadius + 5} fill="none" stroke="white" strokeWidth="2" />
                <g stroke={spikeColor} strokeWidth="4">
                    {spikes.map((spike, index) => {
                        const angle = (spike.angle * Math.PI) / 180;
                        const x1 = centerX + (sunRadius + 5) * Math.cos(angle);
                        const y1 = centerY - (sunRadius + 5) * Math.sin(angle);
                        const x2 = centerX + (sunRadius + 5 + spike.length) * Math.cos(angle);
                        const y2 = centerY - (sunRadius + 5 + spike.length) * Math.sin(angle);
                        return <line key={index} x1={x1} y1={y1} x2={x2} y2={y2} />;
                    })}
                </g>
            </g>
        </svg>
    );
};

export default SunSvgIcon;

export const SunriseSun = () => (
    <SunSvgIcon
        sunRadius={60}
        longSpikeLength={30}
        shortSpikeLength={15}
        sunColorStart="rgb(255,94,77)"
        sunColorEnd="rgb(255,165,0)"
        spikeColor="orange"
    />
);

export const UVARiseSun = () => (
    <SunSvgIcon
        sunRadius={60}
        longSpikeLength={30}
        shortSpikeLength={15}
        sunColorStart="rgb(255,165,0)"
        sunColorEnd="rgb(255,223,0)"
        spikeColor="orange"
    />
);

export const UVBRiseSun = () => (
    <SunSvgIcon
        sunRadius={60}
        longSpikeLength={30}
        shortSpikeLength={15}
        sunColorStart="rgb(255,223,0)"
        sunColorEnd="rgb(255,248,145)"
        spikeColor="yellow"
    />
);

export const SolarNoonSun = () => (
    <SunSvgIcon
        sunRadius={60}
        longSpikeLength={30}
        shortSpikeLength={15}
        sunColorStart="rgb(255,248,145)"
        sunColorEnd="rgb(255,255,0)"
        spikeColor="yellow"
    />
);
