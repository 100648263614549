import React from 'react';
import SunIcon, {isMoonIcon, isSunIcon} from '../icons/sun_icon'
import {formatDayAndTime} from "../utils/time";
import {calculateCoordinates, calculateSunpathDegrees, timeToDegrees} from "../utils/math";

const { DateTime, Duration } = require("luxon");

export const SunTypes = {
    Red: 'red',
    UVA: 'uva',
    UVB: 'uvb',
    Night: 'night',
    Twilight: 'twilight',
    BlueHour: 'blue_hour',
    SolarNoon: 'noon',
};

export const MoonTypes = [SunTypes.Twilight, SunTypes.BlueHour, SunTypes.Night];

function calculateRotation(dayLength, nightLength) {
    const isRotated = true;
    if (!isRotated) {
        return 0
    }
    // Rotate half the difference between 180 and the nightLength
    // so that it is centered. Plus of minus based on winter vs summer
    const diff = Math.abs(180 - nightLength) / 2;
    return dayLength > nightLength ? diff : -diff;
}

const SunPath = ({
                    radius = 200,  // Outer circle radius
                    strokeWidth = 5,  // Stroke width for circles
                    nightColor = "black",  // Stroke color for the night circle
                    dayColor = "grey",  // Stroke color for the day circle
                    fillColor = "white",  // Fill color for the circle
                    sunriseTime,
                    sunsetTime,
                    solarNoon,
                    nadir,
                    sunElevationTime = new DateTime(),
                    sunType = SunTypes.Red,
                    onMouseLeave,
                    children,
                }) => {

//    console.log('\n\n------------Rendering SunPath---------------')
    console.log('sunElevationTime', formatDayAndTime(sunElevationTime))
    console.log('solarNoon', solarNoon)
    const offsetDegrees = timeToDegrees(solarNoon);
    const solarNoonDegrees = 0;
    const sunriseDegrees = calculateSunpathDegrees(sunriseTime, offsetDegrees);
    const sunsetDegrees = calculateSunpathDegrees(sunsetTime, offsetDegrees);
    const nadirDegrees = calculateSunpathDegrees(nadir, offsetDegrees);
    const currentDegrees = calculateSunpathDegrees(sunElevationTime, offsetDegrees);

    // console.log('sunriseDegrees', sunriseDegrees)
    // console.log('sunsetDegrees', sunsetDegrees)
    // console.log('solarNoonDegrees', solarNoonDegrees)
    // console.log('nadirDegrees', nadirDegrees)
    // console.log('currentDegrees', currentDegrees)
    // Calculate the point on the circle where the icon should be placed
    const nightLength = sunriseDegrees - sunsetDegrees;
    const dayLength = 360 - nightLength;

    // console.log('dayLength', dayLength)
    // console.log('nightLength', nightLength)
    const nightDashArray = `${nightLength} ${dayLength}`;  // Stroke dash pattern for the outer circle
    const dayDashArray =  `0 ${nightLength} ${dayLength} ${dayLength}`; //"0 180 180 180";  // Stroke dash pattern for the inner circle

    const rotation = calculateRotation(dayLength, nightLength);

    function calculateDegrees(degrees) {
        return degrees;

        // return degrees - 90 + rotation + nightLength;
    }

    const sunCoords = calculateCoordinates(radius, currentDegrees);
    const sunriseCoords = calculateCoordinates(radius, sunriseDegrees);
    const sunsetCoords = calculateCoordinates(radius, sunsetDegrees);
    const noonCoords = calculateCoordinates(radius, solarNoonDegrees);
    const nadirCoords = calculateCoordinates(radius, nadirDegrees);

    // console.log('sunCoords',sunCoords)
    // console.log('sunriseCoords',sunriseCoords)
    // console.log('sunsetCoords',sunsetCoords)
    // console.log('noonCoords',noonCoords)
    // console.log('nadirCoords',nadirCoords)
    // console.log('nightDashArray',nightDashArray)
    // console.log('dayDashArray',dayDashArray)
    // console.log('rotation',rotation)

    const buffer = strokeWidth / 2;  // Buffer to avoid clipping

    // Adjusted viewBox size and origin based on outerRadius and buffer
    const viewBoxSize = (radius + buffer) * 2;
    const viewBox = `-${radius + buffer} -${radius + buffer} ${viewBoxSize} ${viewBoxSize}`;

    const scale = .5;

    const handleOnMouseLeave = (e) => {
        e.stopPropagation();
        if (onMouseLeave) {
            onMouseLeave();
        }
    };

    return (
        <div className="svg-container">
        <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" onMouseLeave={handleOnMouseLeave} overflow="visible" style={{ width: 'calc(100% - 90px)', height: 'calc(100% - 90px)' }}>
            <g transform={`rotate(${rotation})`}>
                {/* Outer circle with customizable stroke and fill */}
                <circle
                    r={radius}
                    stroke={nightColor}
                    stroke-width={strokeWidth}
                    pathLength={360}
                    stroke-dasharray={nightDashArray}
                    fill={fillColor}
                />

                {/* Inner circle with customizable stroke */}
                <circle
                    r={radius}
                    stroke={dayColor}
                    stroke-width={strokeWidth}
                    pathLength={360}
                    stroke-dasharray={dayDashArray}
                    fill="none"
                />
            </g>

            {isSunIcon(sunType) && <g transform={`translate(${(-radius * scale) + sunCoords.x + 40}, ${(-radius * scale) + sunCoords.y}) scale(${scale})`}>
                <SunIcon sunType={sunType} />
            </g>}
            {isMoonIcon(sunType) && <g transform={`translate(${(-radius * scale) + sunCoords.x}, ${(-radius * scale) + sunCoords.y + 55}) scale(${scale})`}>
                <SunIcon sunType={sunType} />
            </g>}

            {/* Align the text/content to be centered within the inner circle */}
            {children && (
                <foreignObject
                    x={-radius}
                    y={-radius}
                    width={radius * 2}
                    height={radius * 2}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        {children}
                    </div>
                </foreignObject>
            )}
        </svg>
        </div>
    );
};

export default SunPath;
