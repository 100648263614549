import React, {createContext, useContext, useState, useEffect, useMemo, useRef} from 'react';
import {
    getGeolocation,
    getLocationFromCoords,
    getTimeZone,
    getDefaultCoordinates,
    isDefaultCoordinates, getCoordinates, findLocation,
} from '../utils/geo';
import {getDateTime, isDateSame} from "../utils/time";
import {Days, useCurrentTime} from "../hooks/use-current-time";
import {useSunTimes} from "../hooks/use-sun-times";

// Create the context for the solar clock
const SolarClockContext = createContext();

export const useSolarClock = () => {
    // Custom hook to access context values
    const context = useContext(SolarClockContext);
    if (!context) {
        throw new Error('useSolarClock must be used within a SolarClockProvider');
    }
    return context;
};

export const SolarClockProvider = ({children}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [coordinates, setCoordinates] = useState(getDefaultCoordinates());
    const [location, setLocation] = useState('Portland, Oregon');
    const [timezone, setTimezone] = useState('America/Los_Angeles');
    const [displayDate, setDisplayDate] = useState(getDateTime(Days.Today, timezone));
    const { currentTime, startClock, stopClock, syncCurrentTime} = useCurrentTime(timezone)

    // Use Effect for obtaining coordinates and updating location
    useEffect(() => {
        setIsLoading(true);
        try {
            getGeolocation().then(({coords}) => {
                const {latitude, longitude} = coords;
                if (!isNaN(latitude) && !isNaN(longitude)) {
                    Promise.all([getLocationFromCoords(latitude, longitude), getTimeZone(coords.latitude, coords.longitude)])
                        .then(([{address}, {zoneName}]) => {
                            const coords = {
                                latitude: Math.round(latitude * 10000) / 10000,
                                longitude: Math.round(longitude * 10000) / 10000,
                            };
                            initLocationData(`${address.city}, ${address.state}`, coords, zoneName)
                        setIsLoading(false);
                    });
                } else {
                    setIsLoading(false);
                }
            });
        } catch (e) {
            console.error('Error detecting location', e.message);
            setIsLoading(false);
        }
    }, []);

    const sunTimes = useSunTimes(coordinates, displayDate, timezone);

    useEffect(() => {
        if (isDateSame(currentTime, Days.Today)) {
            setDisplayDate(currentTime);
        }
    }, [currentTime]);

    useEffect(() => {
        syncCurrentTime();
    }, [coordinates.latitude, coordinates.longitude, location, timezone]);

    useEffect(() => {
        if (isDateSame(currentTime, displayDate)) {
            startClock()
        } else {
            stopClock();
        }
    }, [displayDate]);

    const isDateToday = () => {
        return isDateSame(displayDate, Days.Today);
    }

    const isDateTomorrow = () => {
        return isDateSame(displayDate, Days.Tomorrow);
    }

    function initLocationData(location, coordinates, timezone) {
        setLocation(location);
        setCoordinates(coordinates);
        setTimezone(timezone);
    }

    const handleLocationChange = async (loc) => {
        const {location, coordinates, timezone} = await findLocation(loc);
        console.log('handleLocationChange', location, coordinates, timezone)
        console.log('handleSubmit')
        console.log('location', location)
        console.log('coordinates', coordinates)
        console.log('timezone', timezone)

        initLocationData(location, coordinates, timezone);
    };

    const handleDisplayDateChange = (newDate) => {
        setDisplayDate(newDate)
    };

    const findLocation = async (location) => {
        setIsLoading(true);
        const coordinates = await getCoordinates(location);
        let timezone;
        if (!isDefaultCoordinates(coordinates)) {
            timezone = await getTimeZone(coordinates.latitude, coordinates.longitude)
        } else {
            timezone = 'America/Los_Angeles';
        }
        setIsLoading(false);
        return {location, coordinates, timezone}
    }

    // Provide context to children components
    return (
        <SolarClockContext.Provider
            value={{
                coordinates,
                location,
                timezone,
                currentTime,
                displayDate,
                today: Days.Today,
                tomorrow: Days.Tomorrow,
                sunTimes,
                isDateToday,
                isDateTomorrow,
                handleDisplayDateChange,
                handleLocationChange,
                isLocationLoading: isLoading,
                findLocation,
            }}
        >
            {children}
        </SolarClockContext.Provider>
    );
};
