import axios from 'axios';

const DefaultLat = 45.5117;
const DefaultLng = -122.6156;

export function getDefaultCoordinates() {
    return {location: 'Portland, OR', latitude: DefaultLat, longitude: DefaultLng};
}

export function isDefaultCoordinates(coords) {
    return coords.longitude === DefaultLng && coords.latitude === DefaultLat;
}

export function getGeolocation() {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve(position);
                },
                (error) => {
                    reject(error);
                },
                {
                    enableHighAccuracy: true, // Optional: Use high accuracy if available
                    timeout: 10000,           // Optional: Timeout after 10 seconds
                    maximumAge: 0,            // Optional: Do not cache previous location
                }
            );
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
}

export async function getCoordinates(location) {
    try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(location)}`);
        if (response.data && response.data.length > 0) {
            const { lat, lon } = response.data[0];
            return { location, latitude: parseFloat(lat), longitude: parseFloat(lon) };
        } else {
            return getDefaultCoordinates();
        }
    } catch (error) {
        console.log(error)
        throw new Error('Error fetching coordinates for the location');
    }
}


export const getLocationFromCoords = async (latitude, longitude) => {
    const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
    );
    if (!response.ok) {
        throw new Error("Failed to fetch location data.");
    }
    return await response.json();
};

const TimeZoneDbAPIKey = "8DPFDKTBAFV1";

export const getTimeZone = async (latitude, longitude) => {
    const defaultTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
        const response = await fetch(
            `http://api.timezonedb.com/v2.1/get-time-zone?key=${TimeZoneDbAPIKey}&format=json&by=position&lat=${latitude}&lng=${longitude}`
        );
        if (!response.ok) {
            return defaultTz;
        }
        const { zoneName} = await response.json();
        return zoneName;
    } catch (e) {
        console.warn('Unable to retrieve timezone', e.message);
        return defaultTz;
    }
};
