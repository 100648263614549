import React, {useState} from "react";
import {useSolarClock} from "../providers/solar-clock-provider";

function LocationForm({handleLocationChange, location, onSuccess}) {
    const [inputLocation, setInputLocation] = useState(location);

    const onChangeLocation = (event) => {
        setInputLocation(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await handleLocationChange(inputLocation);
        onSuccess();
    };

    return <div className="location-form"><input type="text" value={inputLocation} onChange={onChangeLocation}/>
        <button onClick={handleSubmit}>Submit</button></div>;
}

const Navigation = ({ darkTheme, isLocationLoading }) => {
    const [isControlsVisible, setIsControlsVisible] = useState(false);
    const { isDateToday, isDateTomorrow, today, tomorrow, location, handleDisplayDateChange, handleLocationChange} = useSolarClock()

    if (isLocationLoading) {
        return <div className="nav-wrapper-loading"><div className="nav-item-loading">&nbsp;</div></div>
    }
    const styleSuffix = darkTheme ? "-dark" : "-light"
    const todayClass = isDateToday() ? `nav-item-selected${styleSuffix}` : `nav-item${styleSuffix}`
    const tomorrowClass = isDateTomorrow() ? `nav-item-selected${styleSuffix}` : `nav-item${styleSuffix}`
    const navWrapperClass = `nav-wrapper${styleSuffix}`
    const navItemClass = `nav-item${styleSuffix}`

    const displayLocation = isLocationLoading ? '--, --' : location;
    return (
        <div className={navWrapperClass}>
            {!isControlsVisible && <><div className={navItemClass} onClick={() => setIsControlsVisible(true)}>
                {displayLocation}
            </div>            <div className={todayClass} onClick={() => handleDisplayDateChange(today)}>
                Today
            </div>
                <div className={tomorrowClass} onClick={() => handleDisplayDateChange(tomorrow)}>
                    Tomorrow
                </div>
            </>}
            {isControlsVisible && <><LocationForm handleLocationChange={handleLocationChange} location={location} onSuccess={() => setIsControlsVisible(false)}/>
                <div className={navItemClass}><span onClick={() => setIsControlsVisible(false)}>x</span></div></>}
        </div>
    );
};

export default Navigation;
