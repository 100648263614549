import {SunTypes} from "../components/sun_path";
import {isTimeWithinTimeRange} from "../utils/time";

const NightGradient = [
    { pct: 0, color: { r: 11, g: 61, b: 145 }},
    { pct: 100, color: { r: 10, g: 10, b: 10 }}
];
const BlueHourGradient = [
    { pct: 0, color: { r: 255, g: 69, b: 0 }},
    { pct: 5, color: { r: 173, g: 216, b: 230 }},
    { pct: 30, color: { r: 11, g: 61, b: 145 }},
    { pct: 100, color: { r: 10, g: 10, b: 10 }}
];
const TwilightGradient = [
    { pct: 0, color: { r: 255, g: 69, b: 0 }},
    { pct: 15, color: { r: 255, g: 215, b: 0 }},
    { pct: 30, color: { r: 173, g: 216, b: 230 }},
    { pct: 100, color: { r: 51, g: 51, b: 102 }}
];
const SolarNoonGradient = [
    { pct: 1, color: { r: 173, g: 216, b: 230 }},
    { pct: 35, color: { r: 255, g: 215, b: 0 }},
    { pct: 65, color: { r: 255, g: 215, b: 0 }},
    { pct: 99, color: { r: 173, g: 216, b: 230 }}
];
const UVBGradient = [
    { pct: 10, color: { r: 173, g: 216, b: 230 }},
    { pct: 40, color: { r: 255, g: 215, b: 0 }},
    { pct: 60, color: { r: 255, g: 215, b: 0 }},
    { pct: 90, color: { r: 173, g: 216, b: 230 }}
];
const UVAGradient = [
    { pct: 0, color: { r: 173, g: 216, b: 230 }},
    { pct: 33, color: { r: 255, g: 195, b: 0 }},
    { pct: 66, color: { r: 255, g: 255, b: 153 }},
    { pct: 100, color: { r: 135, g: 206, b: 235 }}
];
const RedGradient = [
    { pct: 0, color: { r: 255, g: 87, b: 51 }},
    { pct: 25, color: { r: 255, g: 195, b: 0 }},
    { pct: 50, color: { r: 255, g: 252, b: 153 }},
    { pct: 100, color: { r: 135, g: 206, b: 235 }}
];

export const SolarGradients = {
    [SunTypes.Red]: RedGradient,
    [SunTypes.UVA]: UVAGradient,
    [SunTypes.UVB]: UVBGradient,
    [SunTypes.SolarNoon]: SolarNoonGradient,
    [SunTypes.Twilight]: TwilightGradient,
    [SunTypes.BlueHour]: BlueHourGradient,
    [SunTypes.Night]: NightGradient,
};

const GradientProgression = [NightGradient, BlueHourGradient, TwilightGradient, RedGradient, UVAGradient, UVBGradient, SolarNoonGradient]

export function getSunTypeGradient(sunType) {
    // Define background gradients for different times of day
    let gradient;
    if (sunType === SunTypes.Red) {
        // Sunrise/sunset
        gradient = 'linear-gradient(to top, #FF5733 0%, #FFC300 25%, #FFFC99 50%, #87CEEB 100%)';
    } else if (sunType === SunTypes.UVA) {
        // Early morning/late afternoon
        gradient = 'linear-gradient(to top, #ADD8E6 0%, #FFC300 33%, #FFFF99 66%, #87CEEB 100%)';
    } else if (sunType === SunTypes.UVB) {
        // Late morning/Early afternoon
        gradient = 'linear-gradient(to top, #ADD8E6 10%, #FFD700 40%, #FFD700 60%, #ADD8E6 90%)';
    } else if (sunType === SunTypes.SolarNoon) {
        // Peak of sun in the sky
        gradient = 'linear-gradient(to top, #ADD8E6 1%, #FFD700 35%, #FFD700 65%, #ADD8E6 99%)';
    } else if (sunType === SunTypes.Twilight) {
        // Dawn/Dusk
        gradient = 'linear-gradient(to top, #FF4500 0%, #FFD700 15%, #ADD8E6 30%, #333366 100%)'
    } else if (sunType === SunTypes.BlueHour) {
        // Pre-dawn/post-dusk
//        gradient = 'linear-gradient(to top, #FF4500 0%, #FFD700 5%, #0B3D91 15%, #0A0A0A 100%)';
        gradient = 'linear-gradient(to top, #FF4500 0%, #ADD8E6 5%, #0B3D91 30%, #0A0A0A 100%)';
    } else {
        // Night Old
        // gradient = 'linear-gradient(to top, #0B3D91, #0A0A0A)';
        // Night New
        gradient = 'linear-gradient(to top, #0B3D91, #0A2849, #0A0A26, #0A0A0A)';
    }
    return gradient;
}

export function getSunType(sunTimes, currentTime) {
    const {sunrise, sunset, dawn, dusk, uvaRise, uvaSet, uvbRise, uvbSet, solarNoon, nightfall, daybreak, nadir} = sunTimes;
    if (currentTime > nightfall) {
        // console.log('Night')
        return SunTypes.Night;
    } else if (currentTime > dusk) {
        // console.log('BlueHour')
        return SunTypes.BlueHour;
    } else if (currentTime > sunset) {
        // console.log('Twilight')
        return SunTypes.Twilight;
    } else if (currentTime > uvaSet) {
        // console.log('UVA Set')
        return SunTypes.Red;
    } else if (currentTime > uvbSet) {
        // console.log('UVb Set')
        return SunTypes.UVA;
    } else if (isTimeWithinTimeRange(currentTime, solarNoon, 60)) {
        // console.log('Solar Noon')
        return SunTypes.SolarNoon;
    } else if (currentTime >= uvbRise) {
        // console.log('UVB Rise')
        return SunTypes.UVB;
    } else if (currentTime >= uvaRise) {
        // console.log('UVA Rise')
        return SunTypes.UVA;
    } else if (currentTime >= sunrise) {
        // console.log('Sunrise')
        return SunTypes.Red;
    } else if (currentTime >= dawn) {
        // console.log('Dawn Twilight')
        return SunTypes.Twilight;
    } else if (currentTime >= daybreak) {
        // console.log('Daybreak BlueHour')
        return SunTypes.BlueHour;
    } else {
        // console.log('Default Night')
        return SunTypes.Night;
    }
}

